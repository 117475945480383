<template>
    <div class="background">
      <div  style="display: flex; align-items:center; height:100%; width:100%; justify-content:center; flex-direction: row; flex-wrap: wrap;">
            <h1 style="font-size: 3em; color: #fff;" >Itinerary</h1>
            
            <div style="display: flex; width: auto; justify-content: flex-start; margin-left: 2%;">
                <div>
                    
                    <img src="../assets/Itinerary.png" style="height: 150px;" alt="">
                </div>
                
            </div>
      </div>
   </div>
   <header class="header">
        <p class="container">We’re thrilled that you’ve chosen to participate in Schulich’s ultimate networking event, celebrating its 30th anniversary!</p>
    </header>

    <main class="container">
        <section class="tips-section">
            <h2>Maximize Your Experience</h2>
            <div class="tips-grid">
                <div class="tip-card">
                    <h3>1. Come Prepared</h3>
                    <p>Treat the networking portion as a series of short interviews. Practice your elevator pitch and dress in Business Professional attire.</p>
                </div>
                <div class="tip-card">
                    <h3>2. Do Your Research</h3>
                    <p>Identify key guests aligned with your goals. Study their recent work and LinkedIn profiles to make a lasting impression.</p>
                </div>
                <div class="tip-card">
                    <h3>3. Be Open</h3>
                    <p>Embrace unexpected conversations. Valuable connections can come from unexpected places - stay curious!</p>
                </div>
            </div>
        </section>

        <section class="agenda-section">
            <h2>Event Agenda – 30th January 2025</h2>
            <p style="margin-bottom: 10px;"><strong>Venue:</strong> OneEleven, 4th Floor, 325 Front St. W, Toronto, ON M5V 3S9. Please <strong>report to this venue by 9:15 AM</strong> for your registration to the event. </p>
            
            <table class="agenda-table">
                <tr style="background-image: linear-gradient(45deg, #0056b3, #000000);">
                    <th>Time</th>
                    <th>Activity</th>
                    <th>Details</th>
                </tr>
                <!-- Add all agenda rows following this pattern -->
                <tr>
                    <td>9:15 AM - 9:40 AM</td>
                    <td>Registration & Opening Address</td>
                    <td>Student registration with coffee. Address by Daniel Bordessa</td>
                </tr>
                <!-- Add remaining agenda items -->
                <tr>
                    <td>9:45 AM - 10:35 AM</td>
                    <td>Panel Discussion 1</td>
                    <td>40 minutes panel discussion, 10 minutes Audience Q&A</td>
                </tr>
                <tr>
                    <td>10:35 AM - 11:00 AM</td>
                    <td>Break</td>
                    <td>Breakfast and Coffee</td>
                </tr>
                <tr>
                    <td>11:00 AM - 11:50 AM</td>
                    <td>Panel Discussion 2</td>
                    <td>40 minutes panel discussion, 10 minutes Audience Q&A</td>
                </tr>
                <tr>
                    <td>11:50 AM - 12:00 PM</td>
                    <td>Closing Remarks</td>
                    <td>Closing Remarks by event organizers</td>
                </tr>
                <tr>
                    <td>12:00 PM - 1:30 PM</td>
                    <td>Networking and Lunch</td>
                    <td>Build connections while enjoying lunch!</td>
                </tr>
            </table>
            <p style="margin-top: 20px;">
                We can’t wait to see you at Day on Bay 2025. This is your opportunity to connect, learn, and grow—so come prepared, stay open, and make the most of it!
            </p>
        </section>


        <div class="contact-info">
            <h3>Need Assistance?</h3>
            <p>Contact <br>  Siddharth Dave: <a href="mailto:siddave@schulich.yorku.ca">siddave@schulich.yorku.ca</a><br>
            Fedor Kornachev: <a href="mailto:fkorn@schulich.yorku.ca">fkorn@schulich.yorku.ca</a></p>
        </div>
    </main>

</template>
<script>

</script>
<style scoped>
.background {
    background: linear-gradient(132deg, #0056b3, #000000, #212335);
    background-size: 400% 400%;
    animation: Gradient 15s ease infinite;
    height: 50vh;
    width: 100%;
    position: static;
    overflow: hidden;
    padding:0;
    margin:0px;
  }

  
  /* Animate Background*/
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
    .event-details {
      padding: 20px;
      max-width: 800px;
      margin: 0 auto;
      font-family: Arial, sans-serif;
      line-height: 1.6;
    }
    
    h1 {
      font-size: 28px;
      margin-bottom: 10px;
    }
    
    h3 {
      margin-top: 10px;
    }
    
    ul {
      list-style: none;
      padding: 0;
    }
    
    li {
      margin: 5px 0;
    }
    
    .register-button {
      padding: 15px 30px; background-image:linear-gradient(45deg, #0056b3, #000000); color: white; text-decoration: none; font-size: 1.2em; border-radius: 5px;
      cursor: pointer;
    }
    
    .register-button:hover {
      background-color: #0056b3;
    }
    video {
    width: 100%;
    max-width: 800px;
    height: auto;
    margin: 20px auto;
    display: block;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #000;
    outline: none;
  }
  .container {
            max-width: 70%;
            margin: 20px auto;
            background-color: white;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
        h1, h2, h3 {
            color: #001f3f;
        }
        ul {
            list-style-type: square;
            padding-left: 20px;
        }
        p {
            line-height: 1.6;
        }
        .download-button {
            display: block;
            text-align: center;
            margin: 20px 0;
        }
        .download-button a {
            text-decoration: none;
            color: white;
            background-color: #007bff;
            padding: 15px 30px;
            border-radius: 5px;
            font-size: 18px;
        }
        .download-button a:hover {
            background-color: #0056b3;
        }
        h2{
            margin-top: 10px;
        }
        * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }

        body {
          
            line-height: 1.6;
            color: #333;
            background-color: #f8f9fa;
        }

        h1, h2, h3 {
            font-family: 'Open Sans', sans-serif;
            color: #001f3f;
            margin-bottom: 1rem;
        }

        .container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 2rem;
        }

        /* Header Section */
        .header {
            text-align: center;
            padding: 3rem 0;
            border-bottom: 1px solid #eee;
        }

        .header h1 {
            font-size: 2.5rem;
            color: #3498db;
            margin-bottom: 1rem;
        }

        /* Tips Section */
        .tips-grid {
            display: grid;
            gap: 2rem;
            margin: 2rem 0;
        }

        .tip-card {
            background: #fff;
            padding: 2rem;
            border-radius: 10px;
            box-shadow: 0 2px 15px rgba(0,0,0,0.1);
            transition: transform 0.3s ease;
        }

        .tip-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 5px 20px rgba(0,0,0,0.15);
        }

        /* Agenda Table */
        .agenda-table {
            width: 100%;
            border-collapse: collapse;
            background: #fff;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0 2px 15px rgba(0,0,0,0.1);
        }

        .agenda-table th,
        .agenda-table td {
            padding: 1.2rem;
            text-align: left;
            border-bottom: 1px solid #eee;
        }

        .agenda-table th {
            color: white;
            font-family: 'Open Sans', sans-serif;
        }

        /* Contact Section */
        .contact-info {
            text-align: center;
            padding: 2rem;
            margin-top: 2rem;
            background-color: #fff;
            border-radius: 10px;
        }

        /* Responsive Design */
        @media (max-width: 768px) {
            .container {
                padding: 1rem;
            }
            
            .tips-grid {
                grid-template-columns: 1fr;
            }
            
            .agenda-table th,
            .agenda-table td {
                padding: 0.8rem;
                font-size: 0.9rem;
            }
        }

        @media (min-width: 769px) {
            .tips-grid {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    </style>