import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Events from "../views/Events.vue";
import LeadershipTeam from "../views/LeadershipTeam.vue";
import NewsLetters from "../views/NewsLetters.vue";
import Resources from "../views/Resources.vue";
import ContactUS from "../views/ContactUS.vue";
import DayOnBay from "../views/DayOnBay.vue";
import CaseComp from "../views/CaseComp.vue";
import Itinerary from "../views/itinerary.vue";



const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { transition: "fade" },
  },
  {
    path: "/events",
    name: "events",
    component: Events,
    meta: { transition: "slide" },
  },
  {
    path: "/team",
    name: "team",
    component: LeadershipTeam,
    meta: { transition: "fade" },
  },{
    path: "/newsletters",
    name: "newsLetters",
    component: NewsLetters,
    meta: { transition: "fade"}, 
  },
  {
    path: '/resources',
    name: 'Resources',
    component: Resources
  },
  {
    path: '/contact-us',
    name: 'ContactUS',
    component: ContactUS
  },
  {
    path: '/day-on-bay',
    name: 'DayOnBay',
    component : DayOnBay,
    meta: { transition: "fade"}, 
  },
  {
    path: '/case-competition',
    name: 'CaseComp',
    component: CaseComp,
    meta : { transition : "fade"}
  },
  {
    path: '/day-on-bay/itinerary',
    name: 'Itinerary',
    component: Itinerary,
    meta : { transition : "fade"}

  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/home'
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


export default router;
